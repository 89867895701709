import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import {
  H1Styles,
  PBlackStyles,
  CardStyles,
} from '../../../styles/GlobalStyles';
import ResearchResumeComponent from '../ResearchResumeComponent';
import { InnovationResearchViewModel } from '../../../domain/models/ResearchCenterPageInfoViewModel';
import styled from 'styled-components';
import { DocumentCard } from '../../../styles/TransparencyPortalStyles';
import { ResearchInovationScreenStyles } from '../../../screens/research/ResearchInovationScreen';
import HeaderJumbotronComponent from '../HeaderJumbotronComponent';
import { SpecialMobileImageSlider } from '../clinicalResearchCenter/ClinicalResearchStagesComponent';
import FlowImg from '../../../assets/image/flow.svg';
import { PageLoader } from '../../Loader';
import InstitutionalService from '../../../services/InstitutionalService';

export function ResearchInovationComponent({
  innovationResearch,
}: ResearchInovationComponentProps) {
  const institutionalService = new InstitutionalService();

  const [isLoading, setLoading] = useState<Boolean>(false);
  const [documentsData, setDocumentsData] = useState<any>([]);

  const cards: { title: string; link: string }[] = [];
  const cardsFlowDocs: { title: string; link: string }[] = [];

  if (documentsData) {
    documentsData?.document_name?.map((item: any, index: number) => {
      if (documentsData?.documents && documentsData?.documents?.length !== 0) {
        return cards.push({
          title: item,
          link:
            process.env.REACT_APP_WORDPRESS_API +
            documentsData?.documents[index]?.guid,
        });
      }
      return null;
    });

    documentsData?.sub_flow_title?.map((item: any, index: number) => {
      if (
        documentsData?.sub_flow_docs &&
        documentsData?.sub_flow_docs?.length !== 0
      ) {
        return cardsFlowDocs.push({
          title: item,
          link:
            process.env.REACT_APP_WORDPRESS_API +
            documentsData?.sub_flow_docs[index]?.guid,
        });
      }
      return null;
    });
  }

  useEffect(() => {
    const getDocumentsData = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData('/inov_research');
        if (response?.result) {
          setDocumentsData(JSON.parse(response.result)[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar eventos da API Wordpress. Erro => ',
          error
        );
      }
    };
    getDocumentsData();
  }, []);

  return (
    <ResearchInovationScreenStyles fluid>
      <HeaderJumbotronComponent
        title={innovationResearch.title}
        backImage={innovationResearch.image}
      />

      <ResearchResumeComponent
        title={innovationResearch.titleImage2}
        image2={innovationResearch.image2}
        description={innovationResearch.descriptionImage2}
      />

      <Row>
        <Col xs={11} xl={8} className="mx-auto">
          <Row>
            <Col xl="auto" className="mx-auto mt-3 mt-xl-5">
              <H1Styles>{innovationResearch.scientificResearchTitle}</H1Styles>
            </Col>
          </Row>
          <Row>
            <Col xl="auto" className="mx-auto mt-4">
              <PBlackStyles>
                {innovationResearch.scientificResearchDescription}
              </PBlackStyles>
            </Col>
          </Row>
          <Row>
            <Col xl={'auto'} className="mx-auto mt-5">
              <H1Styles>{innovationResearch.searchProjectTitle}</H1Styles>
            </Col>
          </Row>
          <Row>
            <Col xl="auto" className="mx-auto mt-4">
              <PBlackStyles>
                {innovationResearch.searchProjectDescription}
              </PBlackStyles>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <ResearchInovationCardStyles className="m-2 m-xl-4">
                <span className="my-auto">Problema a ser resolvido</span>
              </ResearchInovationCardStyles>
            </Col>
            <Col xl={4}>
              <ResearchInovationCardStyles className="m-2 m-xl-4">
                <span className="my-auto">Pergunta principal</span>
              </ResearchInovationCardStyles>
            </Col>
            <Col xl={4}>
              <ResearchInovationCardStyles className="m-2 m-xl-4">
                <span className="my-auto">Hipóteses (uma ou mais)</span>
              </ResearchInovationCardStyles>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col xl={4} className="m-2 m-xl-4">
              <ResearchInovationCardStyles>
                <span className="my-auto">
                  Premissas que sustentam <br />a hipótese
                </span>
              </ResearchInovationCardStyles>
            </Col>
            <Col xl={4} className="m-4">
              <ResearchInovationCardStyles>
                <span className="my-auto">Previsões</span>
              </ResearchInovationCardStyles>
            </Col>
          </Row>
          <Row>
            <Col xl={8} className="mx-xl-auto text-xl-center mt-5 mb-4">
              <H1Styles>{innovationResearch.videoTitle}</H1Styles>
            </Col>
          </Row>
          <Row>
            <Col xl="auto" className="mx-auto mt-4">
              <video controls src={innovationResearch.videoLink} />
            </Col>
          </Row>
          <Row>
            <Col xl={8} className="mx-auto text-xl-center mt-5">
              <H1Styles>{innovationResearch.searchFlow}</H1Styles>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto text-xl-center mt-5">
              {isMobile ? (
                <SpecialMobileImageSlider>
                  <img src={FlowImg} alt="Fluxo de submissão" />
                </SpecialMobileImageSlider>
              ) : (
                <img
                  className="w-100"
                  src={innovationResearch.searchFlowImage}
                  alt="Fluxo de submissão"
                />
              )}
            </Col>
          </Row>

          <Row>
            {cardsFlowDocs && cardsFlowDocs.length !== 0 ? (
              cardsFlowDocs?.map((item) => {
                return (
                  <Col xl={4} className="mx-auto mb-4" key={item.title}>
                    <DocumentCard
                      href={item.link}
                      target="blank"
                      download
                      className="download"
                    >
                      <Card className="border-0">
                        <Card.Body className="justify-content-center">
                          <Card.Title style={{ color: '#72b81f' }}>
                            {item.title}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </DocumentCard>
                  </Col>
                );
              })
            ) : (
              <Col xl={12} className="text-center">
                <p>Nenhum documento encontrado.</p>
              </Col>
            )}
          </Row>
          <Row>
            <Col xl={8} className="mx-auto text-xl-center mt-5 mb-4">
              <H1Styles>Documentos para submissão</H1Styles>
            </Col>
          </Row>
          {!isLoading ? (
            <Row className="mb-5">
              {cards?.length !== 0 ? (
                cards?.map((document) => {
                  return (
                    <Col xl={4} key={document.title}>
                      <DocumentCard
                        href={document.link}
                        target="blank"
                        download
                        className="download"
                      >
                        <Card className="border-0">
                          <Card.Body>
                            <Card.Title>{document.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      </DocumentCard>
                    </Col>
                  );
                })
              ) : (
                <Col xl={12} className="text-center">
                  <p>Nenhum documento encontrado.</p>
                </Col>
              )}
            </Row>
          ) : (
            <PageLoader />
          )}
        </Col>
      </Row>
    </ResearchInovationScreenStyles>
  );
}
interface ResearchInovationComponentProps {
  innovationResearch: InnovationResearchViewModel;
}

const ResearchInovationCardStyles = styled(CardStyles)`
  min-height: 132px;
  span,
  p {
    color: #72b81f;
    text-align: center;
    font-size: 22px;
    line-height: 35px;
    font-weight: 600;
  }
  p {
    color: #000;
  }

  @media screen and (max-width: 1210px) {
    min-height: 95px;

    span,
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;
