import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { PBlackStyles, RoundedImage } from '../../styles/GlobalStyles';
import { handleFormatTextToBrokeLine } from '../../utils/functions';

interface ResumeComponentProps {
  wpData: any;
}

export function ResumeComponent({
  wpData
}: ResumeComponentProps) {
  const images: any = [];

  if (wpData) {
    wpData?.images_descriptions?.map((image: any) => {
      return images.push(process.env.REACT_APP_WORDPRESS_API + image?.guid);
    });
  }

  return (
    <>
      <ResumePhoneSection className="d-md-none">
        <span>{wpData?.mobile_description[0]}</span>
        <img src={images[0]} alt="Imagem" />
        <span>{wpData?.mobile_description[1]}</span>
        <img src={images[2]} alt="Imagem" />
        <img src={images[1]} alt="Imagem" className="mt-4" />
        <span>{wpData?.mobile_description[2]}</span>
        <img src={images[3]} alt="Imagem" />
      </ResumePhoneSection>

      <ResumeDesktopTabletSection className="d-none d-md-block pb-5">
        <Col md={9} className="mx-auto mt-xl-5">
          <Row>
            <Col xl={7} className="mt-5 mb-5">
              <Row>
                <Col md={7}>
                  <Row>
                    <Col className="mb-4">
                      <RoundedImage src={images[0]} />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        verticalAlign: 'bottom',
                      }}
                    >
                      <RoundedImage src={images[1]} />
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row className="h-100">
                    <Col md={12} className="mb-3">
                      <RoundedImage src={images[2]} />
                    </Col>
                    <Col
                      style={{
                        float: 'none',
                        display: 'inline-block',
                        verticalAlign: 'bottom',
                      }}
                    >
                      <RoundedImage src={images[3]} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="mt-3 mb-5 my-auto align-self-center text-col-desc-fmc">
              <PBlackStyles
                className={'mt-auto'}
                dangerouslySetInnerHTML={{
                  __html: handleFormatTextToBrokeLine(wpData?.description),
                }}
              />
            </Col>
          </Row>
        </Col>
      </ResumeDesktopTabletSection>
    </>
  );
}

const ResumeDesktopTabletSection = styled(Row)`
  img {
    width: 100%;
  }

  .text-col-desc-fmc {
    @media screen and (min-width: 1220px) {
      margin-left: 70px !important;
    }
  }
`;

export const ResumePhoneSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 1rem;
    line-height: 25px;
    color: #424242;
    padding: 29px 27px;
    text-align: justify;
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }
`;
