import ReactImageGallery from 'react-image-gallery';
import './styles.css';
import { isMobileOnly, isMobile } from 'react-device-detect';

interface PhotoGalleryProps {
  images: any[];
  showPlayButton?: boolean;
  showFullScreen?: boolean;
  showArrows?: boolean;
  showThumbnails?: boolean;
}

export const PhotoGallery = ({
  images,
  showArrows,
  showFullScreen,
  showPlayButton = false,
  showThumbnails,
}: PhotoGalleryProps) => {
  return (
    <div className="image-gallery-wrapper">
      <ReactImageGallery
        items={images}
        showThumbnails={isMobile ? true : showThumbnails}
        showPlayButton={showPlayButton}
        showNav={isMobile ? false : showArrows}
        showBullets={isMobile ? true : false}
        showFullscreenButton={showFullScreen}
        lazyLoad
      />
    </div>
  );
};
